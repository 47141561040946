import React from 'react';
import LayoutsES from '../../components/layouts-es';
import './feature.scss';
import SEO from '../../components/common/SEO'


export default function FeatureES(){
    const seo = {
        title: "Workhub | Features | Free EHS Management Software",
        description:
            "Workhub offers an all-in-one EHS Management Software with over 60+ training courses and tools to manage certificate tracking, policies, workplace screening, inspections, incident reporting, safety meetings, suggestions and plenty more.",
        }
    return (
        <LayoutsES>
            <SEO {...seo} />
            <img className='feature_image' src="/features/website_feature.png" alt='website feature description'/>
                <section className="feature_service_area sec_pad feature_es esp-section">
                    <span className="explain-grouping col=lg-12" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Onboarding & Worker Compliance</h2></span>
                    <div className="container" style={{marginTop: '30px'}}>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-training.svg" alt="Training Icon" />
                                        </div>
                                        <h4 className="h_head">Entrenamiento en Línea </h4>
                                        <p>
                                        Mas de 70 cursos gratuitos de los cuales 
                                        más de 30 están en español o usted puede 
                                        agregar sus propios cursos personalizados 
                                        y su cuestionario.
                                        </p>
                                    </div>
                                
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-certificates.svg" alt="Certificate Tracking Icon" />
                                    </div>
                                    <h4 className="h_head">Seguimiento de Certificados</h4>
                                    <p>Lleve registro de cualquier certificado de 
                                    un trabajador expedido por terceros para 
                                    designación profesional. </p>
                                </div>
                                
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-policies.svg" alt="Policies Icon" />
                                        </div>
                                        <h4 className="h_head">Políticas de Seguridad</h4>
                                        <p>Almacene sus políticas con control de 
                                            versión y obtenga firma digital de sus 
                                            trabajadores post revisión. </p>
                                    </div>
                                    
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-procedures.svg" alt="Procedures Icon" />
                                        </div>
                                        <h4 className="h_head">Procedimientos Operativos</h4>
                                        <p>Incorpore sus procedimientos de seguridad 
                                        y rastree que trabajadores los han revisado. </p>
                                    </div>
                                    
                            </div>

                            
                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-competencies.svg" alt="Competencies Icon" />
                                        </div>
                                        <h4 className="h_head">Competencias</h4>
                                        <p>Cerciórese de que sus trabajadores 
                                        son competentes completando tareas 
                                        de requisitos previos y evaluando el 
                                        rendimiento. </p>
                                    </div>
                                    
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-orientations.svg" alt="Orientations Icon" />
                                        </div>
                                        <h4 className="h_head">Orientaciones de Contratista</h4>
                                        <p>Almacene sus orientaciones con un 
                                            conveniente acceso de contratista y rastree 
                                            el cumplimiento</p>
                                    </div>
                                    
                            </div>

                            {/* <RewardsBanner/> */}

                        <span className="explain-grouping col-lg-12" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Form Tools & Ongoing Tasks </h2></span>
                            
                        <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-screening.svg" alt="Workplace Screening Icon" />
                                        </div>
                                        <h4 className="h_head">Evaluaciones del Lugar de Trabajo</h4>
                                        <p>Regule el acceso a sus sucursales con 
                                        formularios de evaluación personalizables 
                                        y rastree los datos de las visitas a lo largo 
                                        del tiempo.</p>
                                    </div>
                                
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-inspections.svg" alt="Inspections Icon" />
                                        </div>
                                        <h4 className="h_head">Inspecciones</h4>
                                        <p>Programe inspecciones requeridas sobre 
                                        los activos, documente hallazgos y asigne 
                                        elementos de acción sobre los problemas</p>
                                    </div>
                        
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-incidents.svg" alt="Incidents Icon" />
                                        </div>
                                        <h4 className="h_head">Seguimiento de Incidentes</h4>
                                        <p>Realice seguimiento de accidentes, 
                                        peligros, cuasi accidentes, y mantenga 
                                        todos sus documentos de incidentes en un 
                                        solo lugar.</p>
                                    </div>
                                    
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-behaviour-observation.svg" alt="Behaviour Observations Icon" />
                                        </div>
                                        <h4 className="h_head">Observaciones de comportamiento</h4>
                                        <p>Evalué el comportamiento de sus 
                                        trabajadores para identificar y evaluar 
                                        cualquier habito laboral inseguro. </p>
                                    </div>
                        
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-action-items.svg" alt="Action Items Icons" />
                                        </div>
                                        <h4 className="h_head">Elementos de Acción</h4>
                                        <p>Los elementos de acción pueden 
                                        ser registrados y asignados a varios 
                                        componentes, de esta manera usted puede 
                                        fácilmente rastrear el cumplimiento. </p>
                                    </div>
                            
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item" >
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-safety-meetings.svg" alt=" Safety Meetings Icon" />
                                        </div>
                                        <h4 className="h_head">Reuniones de Seguridad</h4>
                                        <p>Programe reuniones de seguridad, cree un 
                                        sendero de documentación digital de lo 
                                        discutido, y registre la asistencia. </p>
                                    </div>
                            
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-forms.svg" alt="Forms Icon" />
                                        </div>
                                        <h4 className="h_head">Formularios y Registros </h4>
                                        <p>Haga seguimiento de inspecciones a 
                                        vehículos, reuniones de seguridad y 
                                        formularios de incidentes con numerosos 
                                        tipos de envíos. </p>
                                    </div>
                            
                            </div>

                            <span className="explain-grouping col-lg-12" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Communication & Collaboration</h2></span>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-bulletins.svg" alt="Bulletins Icon" />
                                        </div>
                                        <h4 className="h_head">Boletines y Alertas</h4>
                                        <p>Mantenga a los trabajadores actualizados 
                                        sobre alertas y actualizaciones con 
                                        confirmaciones de lectura automáticas para 
                                        evaluar el número de lectores.</p>
                                    </div>
                                
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-suggestions.svg" alt="Suggestions Icon" />
                                        </div>
                                        <h4 className="h_head">Sugerencias</h4>
                                        <p>Permítales a los trabajadores aportarle 
                                        ideas que hagan mejorar su lugar de 
                                        trabajo. </p>
                                    </div>
                                    
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item" >
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-polls.svg" alt="Polls Icon" />
                                        </div>
                                        <h4 className="h_head">Sondeos</h4>
                                        <p>Tome decisiones democráticas sondeando las preferencias de sus trabajadores. </p>
                                    </div>
                                
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-surveys.svg" alt="Surveys Icon" />
                                        </div>
                                        <h4 className="h_head">Encuestas de Percepción</h4>
                                        <p>Realice encuestas anónimas a sus 
                                        trabajadores para saber como se sienten 
                                        con el programa de seguridad.</p>
                                    </div>
                            
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item" >
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-subordinates.svg" alt="Worker Management Icons " />
                                        </div>
                                        <h4 className="h_head">Gestión de Trabajadores</h4>
                                        <p>Implemente el sistema de jerarquía y 
                                        permítales a los supervisores monitorear el 
                                        progreso del cumplimiento de su equipo. </p>
                                    </div>
                                    
                            </div>

                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item" >
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-whistleblower.svg" alt="Whistleblower Icon" />
                                        </div>
                                        <h4 className="h_head">Denuncias</h4>
                                        <p>Proteja su reputación y la de los 
                                        trabajadores permitiéndoles reportar 
                                        anónimamente violaciones a las políticas.</p>
                                    </div>
                                
                            </div>

                            {/* <span class="explain-grouping" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Culture & Recognition</h2>
                            <div style={{maxWidth: '840px'}}> <p>Achieving full workplace compliance is bloody hard; not because workers don’t want to complete their assignments, but because urgent work gets in the way. Unfortunately, important preventative measures often get put off until tomorrow. Then tomorrow never comes. A little encouragement can go a long way towards improving worker compliance scores. </p></div></span> */}

                        
                        {/* <div className="col-lg-4 col-sm-6">
                                <a>
                                    <div className="feature_service_item"  id="not-active">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-joke.svg" alt="Joke of the Week Icon" />
                                        </div>
                                        <h4 className="h_head">Joke of the Week</h4>
                                        <p>Inject a little humor into the workplace; smiles and laughs are contagious! Make workers earn the right to hear the joke though, by specifying a minimum number of requirements to be completed each week, or a minimum compliance score. </p>
                                    </div>
                                </a>
                            </div>

                        <div className="col-lg-4 col-sm-6">
                            <Link to='/features/branded-apparel/'>
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-rewards.svg" alt="Branded Apparel Icon" />
                                        </div>
                                        <h4 className="h_head">Branded Apparel</h4>
                                        <p>Make completing safety requirements a priority by incentivizing them with our <nobr>in-house</nobr> rewards program. Better yet, reward them with customized company swag so they can wear it with pride.</p>
                                    </div>
                            
                            </div>

                            
                            <div className="col-lg-4 col-sm-6">
                            <Link to='/features/scratch-cards/'>
                                    <div className="feature_service_item" >
                                        <div className="icon" >
                                            <img src="/features/scratch-cards/scratch-cards-icon.png" style={{width: '200px'}}  alt="" />
                                        </div>
                                        <h4 className="h_head">Scratch Cards</h4>
                                        <p>Have your supervisors recognize exemplary behavior the moment they see it. Scratch cards are an exciting way for workers to earn points towards branded apparel and other rewards.</p>
                                    </div>
                                    
                            </div> */}

                            <span className="explain-grouping col-lg-12" id="explain-grouping-mtop" style={{margin: '20px auto',padding: '1rem'}}> <h2>Reference Materials</h2></span>

                        <div className="col-lg-4 col-sm-6">
                    
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-sds.svg" alt="SDS Icon" />
                                        </div>
                                        <h4 className="h_head">Fichas de Datos de Seguridad</h4>
                                        <p>Agregue sus HDS para asegúrese que sus 
                                            trabajadores tienen acceso a estas de 
                                            manera zzfácil justo desde su celular o 
                                            tableta. </p>
                                    </div>
                                
                            
                            </div>

                            
                            <div className="col-lg-4 col-sm-6">
                        
                                    <div className="feature_service_item" >
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-legislation.svg" alt="Legislation Icon" />
                                        </div>
                                        <h4 className="h_head">Legislación</h4>
                                        <p>Nuestra librería contiene la legislación de 
                                        todo el mundo la cual puede ser activada 
                                        con un par de clics. </p>
                                    </div>
                            
                            </div>

                            <div className="col-lg-4 col-sm-6">
                            
                                    <div className="feature_service_item">
                                        <div className="icon">
                                            <img src="/app-icons/small/workhub-icon-audits.svg" alt="Audit Icon" />
                                        </div>
                                        <h4 className="h_head">Preparación para Auditorias</h4>
                                        <p>Use protocolos populares de auditoria para 
                                        realizar una auto auditoria y guarde los 
                                        resultados, vincule puntos específicos de 
                                        auditoria a elementos correspondientes en 
                                        el sistema e invite a su auditor a acceder a 
                                        los documentos que ellos requieran.</p>
                                    </div>
                        
                            </div>
                        </div>
                    </div>
                </section>
        </LayoutsES>
    )
}
