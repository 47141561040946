import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layouts'
import FsLightbox from 'fslightbox-react';
import ReactWOW from 'react-wow';
import SEO from '../../components/common/SEO'
import RewardsBanner from '../../components/advertising/Rewards'
import store from '../../store'
import LayoutsES from '../../components/layouts-es';
import FeatureES from './featureES';
export default () => {

    const [surveysToggler, surveysSetToggler] = useState(false);
    const [sdsToggler, sdsSetToggler] = useState(false);
    const [rewardsToggler, rewardsSetToggler] = useState(false);
    const [incidentsToggler, incidentsSetToggler] = useState(false);
    const [formsToggler, formsSetToggler] = useState(false);
    const [competenciesToggler, competenciesSetToggler] = useState(false);
    const [certificatesToggler, certificateSetToggler] = useState(false);
    const [auditsToggler, auditsSetToggler] = useState(false);
    const [actionItemsToggler, actionItemsSetToggler] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const surveysImage = `/features/comingsoon/coming-soon-surveys.jpg`;
    const sdsImage = `/features/comingsoon/coming-soon-sds.jpg`;
    const rewardsImage = `/features/comingsoon/coming-soon-rewards.jpg`;
    const incidentsImage = `/features/comingsoon/coming-soon-incidents.jpg`;
    const formsImage = `/features/comingsoon/coming-soon-forms.jpg`;
    const competenciesImage = `/features/comingsoon/coming-soon-competencies.jpg`;
    const certificatesImage = `/features/comingsoon/coming-soon-certificates.jpg`;
    const auditsImage = `/features/comingsoon/coming-soon-audit.jpg`;
    const actionItemsImage = `/features/comingsoon/coming-soon-action-items.jpg`;

    const [theList, setTheList] = useState([]);

    useEffect(() => {
        // Get all the feature links
        
        let x = document.querySelectorAll(".col-lg-4 a");
        let myarray = []
        for (let i=0; i<x.length; i++){
        // let nametext = x[i].textContent;
        // let cleantext = nametext.replace(/\s+/g, ' ').trim();
        let cleanlink = x[i].href;
        myarray.push([cleanlink]);
        };

        // console.log(myarray)

        var result =  myarray.filter(function (e) {return e != ""});
        let linkList = [];
        result.pop()
        linkList.push(result)

        // This array wouldn't flatten with any of the simpler methods; so here's this.

        function flatten(arr) {
            const res = arr.slice();
            let i = 0;
          
            while (i < res.length) {
              if (Array.isArray(res[i])) {
                res.splice(i, 1, ...res[i]);
              }
              else {
                i++;
              }
            }
          
            return res;
          }
    
          setTheList((flatten(linkList)))

         

}, []);

const [preferredLanguage, setPreferredLanguage] = useState(
    store.getState().preferredLanguage ?? "eng"
  )
  /**
   * Whenever there is a change in the language selected from the Dropdown component, there is a dispatch of the current selected language
   * when that changes this callback function runs
   * and to stop listening, invoke the function returned by the subscribe
   */

  const unsubscribe = store.subscribe(() => {
    setPreferredLanguage(store.getState().preferredLanguage);
    if(preferredLanguage !== store.getState().preferredLanguage){
        unsubscribe();
      }
  })

  /**
   * whenever the component is dismounted then stop listening to the changes in the preferred language states
   */
  useEffect(() => {
    return () => { unsubscribe() }
  }, [])

const seo = {
    title: "Workhub | Features | Free EHS Management Software",
    description:
      "Workhub offers an all-in-one EHS Management Software with over 60+ training courses and tools to manage certificate tracking, policies, workplace screening, inspections, incident reporting, safety meetings, suggestions and plenty more.",
  }
    if(store.getState().preferredLanguage === 'esp'){
        return (
            <FeatureES />
        )
    }else{
    return (
        <Layout isModalOpen={isModalOpen}>
                 <SEO {...seo}/>
            <section className="erp_banner_area_two">

                <div className="erp_shap"></div>
                <div className="erp_shap_two" style={{background: 'url(/features/banner_shap.png) no-repeat scroll top left'}}></div>
                <div className="section_intro">
                    <div className="section_container">
                        <div className="intro">
                            <div className=" feature_intro_content">
                                <h1>Robust Compliance Ecosystem</h1>
                                                      
                                <p><em>Don’t freak out,</em> but there are a lot of work-saving features and components that make up Workhub’s comprehensive compliance management system.<br></br><br></br>
                                This shouldn’t be news to you though; you know managing workplace compliance isn’t possible anymore with spreadsheets and file folders. </p>

                                <Link className="general_banner_btn_orange btn_hover wow fadeInLeft" data-wow-delay="0.9s" to="/signup/">Sign Up</Link>
                                {/* <Link
                                        className="general_banner_btn general_banner_btn_two_orange btn_hover" to="/features/overview/">Overview</Link> */}
                                </div>
                        </div>

                        <ReactWOW animation="fadeInUp" delay="0.3s">
                        <div className="animation_img">
                            <div className="container feature_container">
                                <img className="feature_main_mockup" src="/features/feature-header-overview.png" alt="Features Main Banner" />
                            </div>
                        </div>
                        </ReactWOW>
                    </div>
                </div>


                </section>
                <section className="feature_service_area sec_pad">
                <div className="container" style={{marginTop: '30px'}}>

                   <span className="explain-grouping"> <h2>Onboarding & Worker Compliance</h2>
                   <div style={{maxWidth: '840px', margin: '0px auto 20px auto'}}> <p><em>“Welcome to the company. Now go to work.”</em> Yeah, right... That’s not how it works in your business. There are all sorts of material every new hire needs to know to perform their work to the standards you expect. You need to know they have the proper knowledge and skills. Assign them their requirements and delight in watching their compliance grow as they complete the elements of your program. </p>
                   </div>
                   </span>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <Link to="/features/safety-training/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-training.svg" alt="Training Icon" />
                                    </div>
                                    <h4 className="h_head">Online Training</h4>
                                    <p>Allow your staff to gain important workplace awareness concepts from anywhere and at any time. We offer over 60 courses (they’re all free). Add your own custom courses. </p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/certificate-tracking/">
                            <div className="feature_service_item">
                                <div className="icon">
                                    <img src="/app-icons/small/workhub-icon-certificates.svg" alt="Certificate Tracking Icon" />
                                </div>
                                <h4 className="h_head">Certificate Tracking</h4>
                                <p>Track any third-party certifications, like drivers’ licenses, professional designations, and First Aid tickets. Approve certificates that workers submit. </p>
                            </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <Link to="/features/policies/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-policies.svg" alt="Policies Icon" />
                                    </div>
                                    <h4 className="h_head">Policies</h4>
                                    <p>Host and edit your policies with version control. Your workers must acknowledge (or decline) them.</p>
                                </div>
                                </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/standard-operating-procedures/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-procedures.svg" alt="Procedures Icon" />
                                    </div>
                                    <h4 className="h_head">Operating Procedures</h4>
                                    <p>You should expect your tasks to be carried out consistently every time. Define the steps in each procedure to ensure efficiency, quality and safety.  </p>
                                </div>
                                </Link>
                        </div>

                        
                        <div className="col-lg-4 col-sm-6">
                           <Link to="/features/competencies/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-competencies.svg" alt="Competencies Icon" />
                                    </div>
                                    <h4 className="h_head">Competency Assessments</h4>
                                    <p>Assess your workers to demonstrate their proficiencies. You set performance criteria, then assess them against each criterion.  </p>
                                </div>
                                </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <Link to="/features/contractor-orientations/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-orientations.svg" alt="Orientations Icon" />
                                    </div>
                                    <h4 className="h_head">Contractor Orientations</h4>
                                    <p>Host your orientation with convenient sub-contract worker access to them. Tracking is available for administrators at the prime and sub-contractor levels.  </p>
                                </div>
                                </Link>
                        </div>

                        {/* <RewardsBanner/> */}

                        <span className="explain-grouping" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Form Tools & Ongoing Tasks </h2>
                        <div style={{maxWidth: '840px'}}> <p>At your workplace there are probably more forms generated for workplace compliance than in accounting! Pieces of paper, or even fillable online forms don’t cut it anymore, because you know there is a workflow behind every submission. We understand this and offer the tools you need to manage these ongoing forms and workflow tasks. Easy peazy.  </p></div>
                        </span>
                        
                    <div className="col-lg-4 col-sm-6">
                            <Link to="/features/worksite-screening/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-screening.svg" alt="Workplace Screening Icon" />
                                    </div>
                                    <h4 className="h_head">Workplace Screening</h4>
                                    <p>Regulate access to your worksites with custom screening forms. View a manifest of workers on location at any time. Plus, log visitor and worker access in case contract tracing is required later. </p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/inspections/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-inspections.svg" alt="Inspections Icon" />
                                    </div>
                                    <h4 className="h_head">Inspections</h4>
                                    <p>Schedule inspections for each asset categories or location. The system will send notifications and log action items automatically whenever inspections identify deficiencies. </p>
                                </div>
                    </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/incident-reporting/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-incidents.svg" alt="Incidents Icon" />
                                    </div>
                                    <h4 className="h_head">Incident Reporting</h4>
                                    <p>Workers can quickly and easily submit near miss and incident reports. Investigators can then associate injuries, asset damages and environmental impacts. They can also identify root causes and log associated action items. </p>
                                </div>
                                </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                           <Link to='/features/behavior-observations/'>
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-behaviour-observation.svg" alt="Behaviour Observations Icon" />
                                    </div>
                                    <h4 className="h_head">Behavior Observations</h4>
                                    <p>Peer observations help ensure workers are exhibiting safe behaviors. The system logs statistics on unsafe habits so you can build an awareness program around those high-risk behaviors. </p>
                                </div>
                     </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                           <Link to="/features/action-items/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-action-items.svg" alt="Action Items Icons" />
                                    </div>
                                    <h4 className="h_head">Action Items</h4>
                                    <p>Action items come from many other components in the software but are managed and tracked centrally through completion to sign-off. </p>
                                </div>
                         </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <Link to="/features/safety-meetings/">
                                <div className="feature_service_item" >
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-safety-meetings.svg" alt=" Safety Meetings Icon" />
                                    </div>
                                    <h4 className="h_head">Safety Meetings</h4>
                                    <p>Quickly record meeting minutes and track attendance. Link to procedures and hazard assessments to show risk levels on site or relating to work being performed. </p>
                                </div>
                         </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <a>
                                <div className="feature_service_item"  id="not-active">
                                    <div className="icon">
                                        <img src="/app-icons/small/temp2.jpg" alt="Hazard Reports Icon" />
                                    </div>
                                    <h4 className="h_head">Hazard Reports<span>COMING SOON</span></h4>
                                    <p>Workers report hazards as they see them. They can be from an inventory of existing hazards associated with your locations or procedures, or if they’ve never been seen before. The tool ensures you assess and eliminate or mitigate them. </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                           <Link to="/features/forms/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-forms.svg" alt="Forms Icon" />
                                    </div>
                                    <h4 className="h_head">Forms</h4>
                                    <p>This is a catch all for any legacy papers, electronic or web-based forms not already offered in Workhub. Scan and upload, email or even fax them in. They can be assigned actions items, reviewed and approved. </p>
                                </div>
                          </Link>
                        </div>

                        <span className="explain-grouping" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Communication & Collaboration</h2>
                        <div style={{maxWidth: '840px'}} id='communication-and-collab'> <p>Like listing all the Workhub features, it's hard to get everyone on the same page. Our software encourages and facilitates two-way communication between management and your front-line workers. Commenting and “liking” are available in most components; these features bring your attention to what would simply have been utterances in the past. </p> </div></span>

                        <div className="col-lg-4 col-sm-6">
                            <Link to="/features/bulletins/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-bulletins.svg" alt="Bulletins Icon" />
                                    </div>
                                    <h4 className="h_head">Bulletins and Alerts</h4>
                                    <p>Keep workers up to date on the various goings-on in your organization. Bulletins and alerts are available in the app and/or can be sent by email or SMS text. Read receipts are automatically recorded to ensure the message got through. </p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/suggestions/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-suggestions.svg" alt="Suggestions Icon" />
                                    </div>
                                    <h4 className="h_head">Suggestions</h4>
                                    <p>Allow workers to provide you with ideas that can make your workplace better. Approve suggestions for sharing so other workers can vote for and comment on their favorites.</p>
                                </div>
                                </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/polls/">
                                <div className="feature_service_item" >
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-polls.svg" alt="Polls Icon" />
                                    </div>
                                    <h4 className="h_head">Polls</h4>
                                    <p>Make decisions a democratic endeavor by polling your workers (or subset of workers) for their preferences. Or create polls that are simply fun and engaging. </p>
                                </div>
                               </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                         <Link to="/features/surveys/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-surveys.svg" alt="Surveys Icon" />
                                    </div>
                                    <h4 className="h_head">Perception Surveys</h4>
                                    <p>Find out what your workers think of your safety program, your culture, or your strategy. Assign surveys to be completed on a chosen frequency and trend your survey results over time. </p>
                                </div>
                         </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                         <Link to="/features/worker-management/">
                                <div className="feature_service_item" >
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-subordinates.svg" alt="Worker Management Icons " />
                                    </div>
                                    <h4 className="h_head">Worker Management</h4>
                                    <p>Assign workers to supervisors to create an organization hierarchy. Managers and supervisors are kept abreast of their subordinate's compliance and ongoing tasks. </p>
                                </div>
                                </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                        <Link to="/features/whistleblower/">
                                <div className="feature_service_item" >
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-whistleblower.svg" alt="Whistleblower Icon" />
                                    </div>
                                    <h4 className="h_head">Whistleblower</h4>
                                    <p>Allow anonymous submission of alleged policy breaches. Whistleblowers can choose from a list of predetermined recipients. All breach data is secured and encrypted for privacy. </p>
                                </div>
                            </Link>
                        </div>

                        <span className="explain-grouping" id="explain-grouping-mtop" style={{margin: '20px auto', padding: '1rem'}}> <h2>Culture & Recognition</h2>
                        <div style={{maxWidth: '840px'}}> <p>Achieving full workplace compliance is bloody hard; not because workers don’t want to complete their assignments, but because urgent work gets in the way. Unfortunately, important preventative measures often get put off until tomorrow. Then tomorrow never comes. A little encouragement can go a long way towards improving worker compliance scores. </p></div></span>

                    
                    <div className="col-lg-4 col-sm-6">
                            <a>
                                <div className="feature_service_item"  id="not-active">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-joke.svg" alt="Joke of the Week Icon" />
                                    </div>
                                    <h4 className="h_head">Joke of the Week</h4>
                                    <p>Inject a little humor into the workplace; smiles and laughs are contagious! Make workers earn the right to hear the joke though, by specifying a minimum number of requirements to be completed each week, or a minimum compliance score. </p>
                                </div>
                            </a>
                        </div>

                    <div className="col-lg-4 col-sm-6">
                        <Link to='/features/branded-apparel/'>
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-rewards.svg" alt="Branded Apparel Icon" />
                                    </div>
                                    <h4 className="h_head">Branded Apparel</h4>
                                    <p>Make completing safety requirements a priority by incentivizing them with our <nobr>in-house</nobr> rewards program. Better yet, reward them with customized company swag so they can wear it with pride.</p>
                                </div>
                          </Link>
                        </div>

                        
                        <div className="col-lg-4 col-sm-6">
                           <Link to='/features/scratch-cards/'>
                                <div className="feature_service_item" >
                                    <div className="icon" >
                                        <img src="/features/scratch-cards/scratch-cards-icon.png" style={{width: '200px'}}  alt="" />
                                    </div>
                                    <h4 className="h_head">Scratch Cards</h4>
                                    <p>Have your supervisors recognize exemplary behavior the moment they see it. Scratch cards are an exciting way for workers to earn points towards branded apparel and other rewards.</p>
                                </div>
                                </Link>
                        </div>

                        <span className="explain-grouping" id="explain-grouping-mtop" style={{margin: '20px auto',padding: '1rem'}}> <h2>Reference Materials</h2>
                        <div style={{maxWidth: '840px'}}> <p>Like a laxative or plunger, reference materials should always be handy in case you need them. Administrators choose what’s relevant to each work site. Workers can quickly and easily look them up.</p></div></span>

                    <div className="col-lg-4 col-sm-6">
                    <Link to="/features/safety-data-sheets/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-sds.svg" alt="SDS Icon" />
                                    </div>
                                    <h4 className="h_head">Safety Data Sheets</h4>
                                    <p>Create a list of materials at your worksites. Print workplace labels and affix them to those said materials. Workers can scan the QR code on the label to quickly access the safety data sheet. </p>
                                </div>
                            </Link>
                        
                        </div>

                        
                        <div className="col-lg-4 col-sm-6">
                       <Link to="/features/legislation/">
                                <div className="feature_service_item" >
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-legislation.svg" alt="Legislation Icon" />
                                    </div>
                                    <h4 className="h_head">Legislation Hosting</h4>
                                    <p>Choose which jurisdictions you operate in, then select from countless acts and regulations to share with your workers. Links are provided to government websites where the legislation is always up to date. </p>
                                </div>
                        </Link>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                         <Link to="/features/audits/">
                                <div className="feature_service_item">
                                    <div className="icon">
                                        <img src="/app-icons/small/workhub-icon-audits.svg" alt="Audit Icon" />
                                    </div>
                                    <h4 className="h_head">Audit Preparation</h4>
                                    <p>Streamline your preparation process by choosing from one of many audit types available, gather documentation to address criteria, and upload auditor results for reference.</p>
                                </div>
                      </Link>
                        </div>
                    </div>
                </div>
                </section>
        </Layout>
    )
                                    }
}