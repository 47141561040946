import React from 'react'
import { Link } from 'gatsby'

function Rewards() {
    return (
        <section className='rewards_banner'>
            <div className='rewards_ban_contain'>

                <div className='rewards_text_contain'>
                    {/* <h2><strong>Hey!</strong></h2> */}
           <h3>Are you looking to incentivize your workers with no hassle?</h3>

           <p>Our Rewards program offers a <strong>massive selection</strong> of branded apparel & goodies to excite your team. You don't even have to worry about ordering or shipping - we take care of it.</p>

           <Link to="/features/branded-apparel/" className="slider_btn btn_hover" style={{maxWidth: '250px', marginTop: '0.75rem'}}>Go to Branded Apparel</Link>

           </div>

           <div className='rewards_image_contain'>

               <img src='/adverts/rewards_banner.png'/>
           </div>
            
            </div>
        </section>
    )
}

export default Rewards
